import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
import { Chart } from './chart/chart.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <blockquote>
      <p parentName="blockquote">{`Stanford researcher, Denise Pope, notes that the Latin root of the word assessment, assidere, means ‘to sit beside,’ and thus has said, “It means understanding students and wanting them to learn and know deeply.”`}</p>
    </blockquote>
    <p>{`At PSI, we believe that assessments are an invaluable tool to inform learning, as well as to consider programmatic strengths and areas for enhancement.  As we strive for more personalised learning, we want assessment to be used AS and FOR student learning with minimal use as an assessment OF Learning.`}</p>
    <p>{`Based on this belief, we have been reviewing our assessments to ensure that we are using the right tools to best understand students’ learning, including researching what other schools find most helpful. Currently we offer a number of assessments of a variety of types as outlined in this `}<a parentName="p" {...{
        "href": "https://docs.google.com/document/d/1YsUIZYDmwuKptq6Ch6tXc7av1NIwDW55X83P9if4Tww/edit?usp=sharing"
      }}>{`document`}</a>{`. Next year, in the Primary School, we are also looking to introduce the Global Strategy Stage (GloSS) and Junior Assessment of Mathematics (JAM) which cover mathematics conceptual understanding and are similar in style to the Developmental Reading Assessment 2nd Ed. (DRA2), in that teachers individually interview students about their understanding.  In the Secondary School, we are exploring IB’s eAssessments as a possible additional assessment in MYP, which includes ePortfolios of coursework and on-screen examinations that assess interdisciplinary understanding..`}</p>
    <Chart mdxType="Chart" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      